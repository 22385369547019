<template>
  <div
    class="page-outter"
    style="padding-bottom: 20px"
  >
    <div class="drug-dd">
      <span
        class="s1"
        @click="goTofirst"
      >首页</span>
      <span class="el-icon-arrow-right"></span>
      <span>{{info.label}}</span>
    </div>
    <div class="drug-d-d1">
      <img
        class="drug-d-d1-img"
        v-lazy="info.img"
      />
      <div class="drug-d-d2">
        <span class="drug-d-d1-label">{{info.label}}</span>
        <div class="drug-d-d1-mode">
          <span class="s1">价格</span>
          <span class="s2">{{info.price}}</span>
          <span class="s3">销量{{info.sales}}</span>
        </div>
        <div class="drug-d-d1-ds">
          <span class="s1">送至</span>
          <span class="d1">
            <span>浙江省杭州市西湖区</span>
            <span class="el-icon-check ss1"></span>
          </span>
          <span class="s2">有货</span>
          <el-tag
            type="warning"
            class="s3"
          >包邮</el-tag>
        </div>
        <div class="drug-d-d1-ds">
          <span class="s1">规格</span>
          <span class="s1-d1">
            <span
              :class="form.activeIndex === index ? 's-d-item s-d-item-active' : 's-d-item'"
              v-for="(item,index) in info.specification"
              :key="index"
            >{{item}}</span>

          </span>
        </div>
        <div class="drug-d-d1-ds">
          <span class="s1">数量</span>
          <el-input-number
            v-model="form.num"
            :min="1"
            :max="10"
            style="margin-left: 10px"
          ></el-input-number>
        </div>
        <div class="drug-d-d1-ds">
          <el-button
            type="warning"
            class="b1"
            @click="add"
          >加入购物车</el-button>
          <el-button
            icon="el-icon-chat-dot-square"
            class="b1"
            @click="advisory"
          >在线咨询</el-button>
        </div>
      </div>
    </div>
    <div class="drug-d-d3">
      商品详情
    </div>
    <div class="drug-d-d4">
      <div class="drug-d-d4-d1">
        <span class="title">商品信息</span>
        <div class="drug-d-d4-d1-tt">
          <span>品牌：{{info.brand}}</span>
          <span class="st2">生产厂商：{{info.manufacturer}}</span>
        </div>
        <div class="drug-d-d4-d1-tt">
          <span>批准文号：{{info.approvalNumber}}</span>
          <span class="st2">不良反应：{{info.adverseReactions}}</span>
        </div>
      </div>
      <div class="drug-d-d4-d1">
        <span class="title">商品说明书</span>
        <table class="drug-table">
          <tr>
            <td class="td1">含量成分</td>
            <td class="td2">{{info.ingredient}}</td>
          </tr>
          <tr>
            <td class="td1">功能主治/适应症</td>
            <td class="td2">{{info.treatment}}</td>
          </tr>
          <tr>
            <td class="td1">企业名称</td>
            <td class="td2">{{info.enterprise}}</td>
          </tr>
          <tr>
            <td class="td1">商品名称</td>
            <td class="td2">{{info.productName}}</td>
          </tr>
          <tr>
            <td class="td1">通用名称</td>
            <td class="td2">{{info.commoName}}</td>
          </tr>
          <tr>
            <td class="td1">用法用量</td>
            <td class="td2">{{info.dosage}}</td>
          </tr>
          <tr>
            <td class="td1">注意事项</td>
            <td class="td2">{{info.precautions}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="drug-d-d5"
      align="center"
    >
      <img v-lazy="info.img" />
    </div>

  </div>
</template>

<script>
export default {
  name: "drugDetails",
  data() {
    return {
      form: {
        num: 1,
        activeIndex: 0,
      },
      info: undefined,
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      const res = JSON.parse(this.$route.query.res);
      this.info = Object.assign({}, res);
    },
    goTofirst() {
      this.$router.push("/drug-info/first");
    },
    add() {
      this.$router.push("/login");
    },
    advisory() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.drug-dd {
  font-size: 14px;
  color: #4b4b4b;
  margin-top: 10px;
  .s1 {
    cursor: pointer;
  }
}
.drug-d-d1 {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
.drug-d-d1-img {
  height: 380px;
}
.drug-d-d1-label {
  font-size: 24px;
  font-weight: 500;
}
.drug-d-d1-mode {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 40px;
  background: #f1f1f1;
  margin-top: 10px;
  span {
    display: inline-block;
  }
  .s1 {
    font-size: 14px;
  }
  .s2 {
    font-size: 32px;
    color: red;
    margin-left: 15px;
  }
  .s3 {
    font-size: 14px;
    position: absolute;
    right: 10px;
  }
}
.drug-d-d1-ds {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  span {
    display: inline-block;
    font-size: 14px;
  }
  .s1 {
    margin-top: 7px;
    min-width: 30px;
  }
  .s2 {
    margin-top: 7px;
    margin-left: 10px;
  }
  .s3 {
    margin-top: 4px;
    margin-left: 10px;
  }
  .b1 {
    width: 180px;
  }
  .d1 {
    position: relative;
    width: 213px;
    background: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-left: 10px;
    .ss1 {
      position: absolute;
      right: 10px;
    }
  }
  .s1-d1 {
    display: block;
  }
  .s-d-item {
    padding: 7px 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
  .s-d-item-active {
    color: #1890ff;
    border: 1px solid #1890ff;
  }
}
.drug-d-d2 {
  margin-left: 10px;
}
.drug-d-d3 {
  height: 40px;
  background: #f1f1f1;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
}
.drug-d-d4 {
  background: #fff;
}
.drug-d-d4-d1 {
  padding: 20px 30px;
  .title {
    display: block;
    font-size: 15px;
  }
}
.drug-d-d4-d1-tt {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  span {
    display: block;
    font-size: 14px;
    width: 406px;
    color: #4d4d4d;
  }
}
.drug-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  td {
    font-size: 14px;
    border: 1px solid #dddddd;
  }
  .td1 {
    width: 150px;
    padding: 7px 13px;
    background: #f3f3f3;
    text-align: center;
  }
  .td2 {
    padding: 7px 10px;
    color: #878687;
    line-height: 1.5;
  }
}
.drug-d-d5 {
  img {
    height: 598px;
  }
}
</style>